<template>
    <div id="main">
        <a>aktualnosci</a>
    </div>
</template>

<script>

</script>

<style scoped>
#main{
    min-height: 90vh;
}
</style>
