<template>
    <nav id="navbar">
        <div id="dropdown">
            <b-dropdown ref="dropdown" variant="transparent" class="dropdown-container">
                <template #button-content>
                    <span class="dropdown-trigger" @click="toggleDropdown">
                        <a>MENU</a>
                    </span>
                </template>
                <b-dropdown-item @click="handleClick('about')">O NAS</b-dropdown-item>
                <b-dropdown-item @click="handleClick('team')">ZESPÓŁ</b-dropdown-item>
                <b-dropdown-item @click="handleClick('estethic')">MEDYCYNA ESTETYCZNA</b-dropdown-item>
                <b-dropdown-item @click="handleClick('cosmetology')">KOSMETOLOGIA</b-dropdown-item>
                <b-dropdown-item @click="handleClick('laserotherapy')">LASEROTERAPIA</b-dropdown-item>
                <b-dropdown-item @click="handleClick('pricelist')">CENNIK</b-dropdown-item>
                <b-dropdown-item @click="handleClick('news')">AKTUALNOŚCI</b-dropdown-item>
                <b-dropdown-item @click="handleClick('gallery')">GALERIA</b-dropdown-item>
                <b-dropdown-item @click="handleClick('contact')">KONTAKT</b-dropdown-item>
            </b-dropdown>
        </div>
        <div id="mid">
            <router-link to="/">KLINIKA MEDYCYNY ESTETYCZNEJ</router-link>
            <div class="logo">
                <router-link to="/">
                    <img src="../assetsPng/logonew.png">
                </router-link>
            </div>
        </div>
        <div id="dropdown">

        </div>
    </nav>
</template>

<script>
import { BDropdown, BDropdownItem, BDropdownDivider } from 'bootstrap-vue-next';

export default {
    components: {
        BDropdown,
        BDropdownItem,
        BDropdownDivider
    },
    methods: {
        handleClick(action) {
            switch (action) {
                case 'about':
                    this.$router.push('/');
                    break;
                case 'team':
                    this.$router.push('/zespol');
                    break;
                case 'estethic':
                    this.$router.push('/medycyna-estetyczna');
                    break;
                case 'cosmetology':
                    this.$router.push('/kosmetologia');
                    break;
                case 'laserotherapy':
                    this.$router.push('/laseroterapia');
                    break;
                case 'pricelist':
                    this.$router.push('/cennik');
                    break;
                case 'news':
                    this.$router.push('/aktualnosci');
                    break;
                case 'contact':
                    this.$router.push('/kontakt');
                    break;
                case 'gallery':
                    this.$router.push('/galeria');
                    break;
                default:
                    console.log('No route defined for: ', action);
                    break;
            }
        },
        toggleDropdown() {
            this.$refs.dropdown.toggle();
        }
    }
}
</script>

<style scoped>
#navbar {
    display: flex;
    background-color: #1f1c1c;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.dropdown-trigger {
    color: #BD924A;
}
#dropdown{
    width: 20%;
}
#mid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #BD924A;
    text-align: center;
    flex-grow: 1;
}

#mid a,
#mid router-link {
    text-decoration: none;
    color: #BD924A;
}

.dropdown-item {
    color: #BD924A;
}

.dropdown-item:hover {
    background-color: rgba(189, 92, 74, 0.1);
}
</style>
