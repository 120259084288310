<template>
    <div class="modal-image" v-if="showModal">
      <div class="modal-content">
        <button id="closeModal" @click="closeModal">
          <img src="../assetsPng/closeicon.png" alt="Close Modal">
        </button>
        <img src="../assetsPng/modal.resized.png" class="modal-main-image">
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      imageSrc: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        showModal: false,
      };
    },
    mounted() {
      this.showModal = true;
    },
    methods: {
      closeModal() {
        this.showModal = false;
        this.$emit('close'); // Opcjonalnie: emitowanie zdarzenia zamknięcia modala
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-image {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Półprzezroczyste tło */
    z-index: 1000; /* Wysoki z-index, aby modal był nad innymi elementami */
    display: flex; /* Użyj Flexboxa dla wyśrodkowania */
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    position: relative; /* Dodane, aby pozycjonowanie przycisku zamknięcia działało poprawnie */
    max-width: 500px; /* Dostosuj maksymalną szerokość */
    background-color: transparent; /* Opcjonalnie: tło dla modal-content */
    padding: 20px; /* Opcjonalnie: wewnętrzny odstęp */
  }
  
  #closeModal {
    position: absolute;
    top: 10px; /* Adjust the offset from the top */
    right: 10px; /* Adjust the offset from the right */
    background: none; /* Remove any default background */
    border: none; /* Remove any default border */
    cursor: pointer; /* Indicate that it's clickable */
    padding: 0; /* Remove default padding */
  }
  
  #closeModal img {
    width: 24px; /* Szerokość ikony zamknięcia */
    height: 24px; /* Wysokość ikony zamknięcia */
  }
  
  .modal-main-image {
    width: 300px; /* Ustawienie stałej szerokości obrazka */
    height: auto; /* Zachowanie proporcji obrazka */
    display: block; /* Zapewnia, że obrazek jest wyświetlany jako blok */
    margin: 0 auto; /* Wyśrodkowanie obrazka w modal-content */
  }
  </style>
  